// @ts-check

import * as THREE from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

let canvas = document.getElementById( 'canvas' );

let camera, scene, renderer;

const loader = new THREE.ObjectLoader();


export function runThree(json) {

    const { innerWidth, innerHeight } = window;

    // CAMERA

    camera = new THREE.PerspectiveCamera( 40, innerWidth / innerHeight, 1, 10000 );
    camera.position.set( 700, 200, - 500 );

    // SCENE

    scene = new THREE.Scene();

    scene.background = new THREE.Color( 0xffffff );

    // add subtle ambient lighting
    const ambientLight = new THREE.AmbientLight( 0x222222 );
    scene.add( ambientLight );

    // add directional light source
    const directionalLight = new THREE.DirectionalLight( 0xffffff );
    directionalLight.position.set( 1, 1, 1 ).normalize();
    scene.add( directionalLight );


    
    
    // RENDERER

    renderer = new THREE.WebGLRenderer( { antialias: true, canvas: canvas } );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( innerWidth, innerHeight );

    // CONTROLS

    const controls = new OrbitControls( camera, renderer.domElement );
    controls.maxPolarAngle = 0.9 * Math.PI / 2;
    controls.enableZoom = false;

    animate();

    //

    window.addEventListener( 'resize', onWindowResize );

}

function onWindowResize() {

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize( window.innerWidth, window.innerHeight );

}

//

function animate() {

    requestAnimationFrame( animate );

    renderer.render( scene, camera );
}


let currentObject;

export function updateMesh(json) {

    if(currentObject) scene.remove(currentObject);

    currentObject = loader.parse( json );
    
    scene.add( currentObject );
}

function align(object) {

    let currentX = 0;
    let padd = 2.5;


    // currently all object's children are in the same position
    // align on x axis
    const box = new THREE.Box3()

    for(let i = 0; i < object.children.length; i++) {
        
        // reset box
        box.makeEmpty();

        // get child
        const child = object.children[i];

        // get child's bounding box
        box.expandByObject(child);

        // get child's width
        const xSize = Math.abs(box.max.x  - box.min.x)

        currentX += (xSize  * 0.5 + padd)

        // move child to the right
        child.position.x = currentX;

        currentX += xSize  * 0.5
    }


    // center

    let mid =currentX * 0.5

    for(let i = 0; i < object.children.length; i++) {
        
        // get child
        const child = object.children[i];

        // move child to the right
        child.position.x -= mid;
    }                

}