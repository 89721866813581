import "./styles.css";

import PartySocket from "partysocket";

import { runThree, updateMesh } from "./runthree";

declare const PARTYKIT_HOST: string;


// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
const conn = new PartySocket({
  host: PARTYKIT_HOST,
  room: "T_90tLDnYh-Qco0NEGq8cTRgtY17phL",
  maxRetries: 0,
  query: {
    userId: "debug",
    name: "debug",
  }
});

runThree();

// You can even start sending messages before the connection is open!
conn.addEventListener("message", (event) => {
  // add(`Received -> ${event.data}`);

  if(typeof event.data !== "string") return

  let msg = JSON.parse(event.data);

  console.log("received message", msg);

  if(msg.type === "ping") {

    conn.send(JSON.stringify({ type: "pong", data: msg.data }));
  }

  if(msg.type === 15) {
    msg = msg.data;
    if(msg.type === "mesh") {
      // debugger
      console.log("received mesh data", msg);
      updateMesh(msg.scene);
    }
  }
});

// Let's listen for when the connection opens
// And send a ping every 2 seconds right after
conn.addEventListener("open", () => {
  console.log("Connected!");
  console.log("Waiting for mesh data");
});

window.$refresh = () => {
  conn.send(JSON.stringify({ type: 100, data: { type: "debug-physics" } }));
}

setInterval(() => {
  conn.send(JSON.stringify({ type: 100, data: { type: "debug-physics" } }));
}, 1000);


let input = {
  forward: false,
  backward: false,
  left: false,
  right: false,
}

window.addEventListener("keydown", (e) => {
  switch(e.key) {
    case "w":
      input.forward = true;
      break;
    case "s":
      input.backward = true;
      break;
    case "a":
      input.left = true;
      break;
    case "d":
      input.right = true;
      break;
  }
  // conn.send(JSON.stringify({ type: 100, data: { type: "player-input", input } }));
});

window.addEventListener("keyup", (e) => {
  switch(e.key) {
    case "w":
      input.forward = false;
      break;
    case "s":
      input.backward = false;
      break;
    case "a":
      input.left = false;
      break;
    case "d":
      input.right = false;
      break;
  }
  // conn.send(JSON.stringify({ type: 100, data: { type: "player-input", input } }));
});